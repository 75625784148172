import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import BasicLayout from '@/layouts/BasicLayout'
const routes = [
  {path: '/login', name: '登陆', component: () => import('@/views/login/index'), hidden: true},
  {
    path: '',
    name: 'index',
    component: BasicLayout,
    meta: { title: '基础', keepAlive: true },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import('@/views/index/index'),
        meta: { title: '首页', noCache: true }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue'),
        meta: { title: '关于', keepAlive: true }
      }
    ]
  },
  {
    path: '/account',
    name: '个人中心',
    component: BasicLayout,
    meta: { title: '个人信息', keepAlive: true },
    redirect: '/account/setting',
    children: [
      {
        path: '/account/setting',
        name: '个人信息',
        component: () => import('@/views/account/index'),
        meta: { title: '个人信息',keepAlive: true}
      },
      {
        path: '/account/password',
        name: '修改密码',
        component: () => import('@/views/account/password'),
        meta: { title: '修改密码',keepAlive: true}
      }
    ]
  },
  {
    path: '/menu',
    name: 'system',
    component: BasicLayout,
    meta: { title: '系统设置', keepAlive: true },
    redirect: '/menu/index',
    children: [
      {
        path: '/menu/index',
        name: 'menuIndex',
        component: () => import('@/views/menu/index'),
        meta: { title: '菜单列表',keepAlive: true}
      },
      {
        path: '/role/index',
        name: 'roleIndex',
        component: () => import('@/views/role/index'),
        meta: { title: '角色列表',keepAlive: true}
      },
      {
        path: '/role/update',
        name: 'roleSave',
        component: () => import('@/views/role/add'),
        meta: { title: '角色添加',keepAlive: true}
      },
      {
        path: '/manager',
        name: 'manager',
        component: () => import('@/views/manager/index'),
        meta: { title: '管理员管理',keepAlive: true}
      },
      {
        path: '/config',
        name: 'config',
        component: () => import('@/views/config/index'),
        meta: { title: '网站设置',keepAlive: true}
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/payment/index'),
        meta: { title: '支付配置',keepAlive: true}
      },
      {
        path: '/slide',
        name: 'slide',
        component: () => import('@/views/slide/index'),
        meta: { title: '广告位管理',keepAlive: true}
      },
      {
        path: '/slide-item',
        name: 'slide-item',
        component: () => import('@/views/slide/itemIndex'),
        meta: { title: '广告管理',keepAlive: true}
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('@/views/category/index'),
        meta: { title: '分类管理',keepAlive: true}
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('@/views/service/index'),
        meta: { title: '客服管理',keepAlive: true}
      },
      {
        path: '/service-stat',
        name: 'service-stat',
        component: () => import('@/views/service/stat'),
        meta: { title: '客服统计',keepAlive: true}
      },
      {
        path: '/channel',
        name: 'channel',
        component: () => import('@/views/channel/index'),
        meta: { title: '渠道管理',keepAlive: true}
      },
      {
        path: '/channel-stat',
        name: 'channel-stat',
        component: () => import('@/views/channel/stat'),
        meta: { title: '渠道统计',keepAlive: true}
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index'),
        meta: { title: '客户信息',keepAlive: true}
      },
      {
        path: '/user-detail',
        name: 'user-detail',
        component: () => import('@/views/user/detail'),
        meta: { title: '客户审核',keepAlive: true}
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order/index'),
        meta: { title: '订单管理',keepAlive: true}
      },
      {
        path: '/order/auto',
        name: 'orderauto',
        component: () => import('@/views/order/auto'),
        meta: { title: '订单管理',keepAlive: true}
      },
      {
        path: '/appsflyer',
        name: 'appsflyer',
        component: () => import('@/views/appsflyer/index'),
        meta: { title: 'Appsflyer',keepAlive: true}
      },
      {
        path: '/package',
        name: 'package',
        component: () => import('@/views/package/index'),
        meta: { title: 'Package',keepAlive: true}
      },
    ]
  },


  {
    path: '/404',
    component: () => import('@/views/exception/404'),
    hidden: true
  },


]

const router = createRouter({
  model:'hash',
  base:process.env.BASE_URL,
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
})

export default router

